import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { isMobile } from "../../services/helpers.service";
import { styles } from "../../styles";
import { Spacer } from "./Spacer";
import { H2, P } from "./Typography";

export type OverviewItemProps = {
  title: string;
  text: string;
  suffix?: string;
  options?: Array<{ label: string; value: string }>;
  optionType?: string;
  onOptionChange?: (event: SyntheticEvent<HTMLSelectElement>) => void;
};

const NameAndTypeContainer = styled.div`
  display: flex;
  
  p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: ${20 / 16}rem;
    margin-top: 0 !important;

    @media screen and (pointer: coarse) {
      font-size: 1.2rem;
    }
  }
`;

const OverflowText = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: ${styles.colors.dark2};
  line-height: ${20 / 16}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (pointer: coarse) {
    font-size: 1.2rem;
  }
`;

const Select = styled.select`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: ${styles.colors.dark2};
  line-height: ${20 / 16}rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  
  &:focus {
    outline: none;
  }

  margin-top: 3px;
  padding: 6px;
  border-radius: 4px;
  border: 1.5px solid ${styles.colors.darkBlue30};
      
  @media screen and (pointer: coarse) {
    font-size: 1.2rem;
  }
`;

const DividerLine = styled.div`
  width: 0.1rem;
  height: 0.9rem;
  opacity: 0.7;
  background: ${styles.colors.primary};
  align-self: center;
`;

export const OverviewItem = ({ title, text, suffix, options, optionType, onOptionChange }: OverviewItemProps) => {
  return (
    <div>
      <H2 fontSizePx={12} lineHeightPx={14.06} color={styles.colors.darkBlue50}>
        {title}
      </H2>
      <Spacer heightPx={2} />
      <NameAndTypeContainer>
        {options && options.length > 1 ?
          <Select onChange={onOptionChange} data-type={optionType}>
            {options.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
          </Select> :
          <OverflowText>{text}</OverflowText>
        }
        {suffix && (
          <>
            {!isMobile() && <Spacer widthPx={5} />}
            {!isMobile() && (<>
              <DividerLine></DividerLine>
            </>
            )}
            {!isMobile() && <Spacer widthPx={5} />}
            <P fontSizePx={14} color={styles.colors.darkBlue75}>
              {suffix}
            </P>
          </>
        )}
      </NameAndTypeContainer>
    </div >
  );
};
