import {
  VENTILATED_WALL_R_VALUE_RSE,
  VENTILATED_WALL_R_VALUE_RSI,
} from "@iko-design-center/shared";
import React, { SyntheticEvent } from "react";
import styled from "styled-components";
import { styles } from "../../../../styles";
import { OverviewItem, OverviewItemProps } from "../../../common/OverviewItem";
import { H1, H2, P, TypographyProps } from "../../../common/Typography";
import { UnstyledButton } from "../../../common/UnstyledButton";
import { Input } from "../../../common/Input";
import { CloseIcon } from "../../../common/Icons";
import { ThermalStateProvider, useThermalState } from "./ThermalStateProvider";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../../store/modal/hooks";

import { PreliminaryTableBody } from "./PreliminaryTableBody";
import { PreliminaryOutcomeTableBody } from "./PreliminaryOutcomeTableBody";
import { renderToString } from 'react-dom/server';
import { Spacer } from "../../../common/Spacer";
import { CorrectionsTableBody } from "./CorrectionsTableBody";
import { CorrectionsOutcomeTableBody } from "./CorrectionsOutcomeTableBody";
import { TotalsOutcomeTableBody } from "./TotalsOutcomeTableBody";
import { useExtendedThermalCalculation } from "./ventilatedWallExtendedCalculation.hook";

const Section = styled.section`
  width: 90%;
  height: 90%;
  background: ${styles.colors.white};
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 5px;

  sub {
    font-size: 62.5%;
  }
`;

const SectionHeader = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Separator = styled.hr<{ margin?: string }>`
  border: none;
  background: #EBEBEB;
  height: ${(props) => props.margin ? '1px' : '2px'};
  margin: ${(props) => props.margin ?? 0};
`;

const TableHeader = styled.div`
  width: 100%;
  padding: 24px 32px 12px 32px;
  display: flex;
  align-items: center;
`;

const TableBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    width: 100%;
    padding-left: 48px;
  }
`;

const TableBodyTitle = styled.h3`
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    writing-mode: vertical-rl;
    width: fit-content;
    height: 100%;
    text-align: center;
    padding: 32px 24px;
    font-weight: 500;

    span {
      white-space: nowrap;
    }
`;

const LeftColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: flex-start;
  align-items: flex-start;
`;

const RightColumn = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0.1666666;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled(UnstyledButton)`
  svg path {
    fill: #2E3341;
  }
`;

const h2Styles = {
  fontWeight: 500,
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

const unitStyles = {
  fontSizePx: 12,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
} as TypographyProps;

export const ExtendedThermalCalculation = () => {
  const { t } = useTranslation() as any
  const { closeModal } = useModal()

  return (
    <ThermalStateProvider>
      <Section onClick={(e) => e.stopPropagation()}>
        <SectionHeader>
          <H1 fontWeight={500}>Thermische berekening</H1>
          <CloseButton>
            <CloseIcon onClick={closeModal} />
          </CloseButton>
        </SectionHeader>

        <Separator />

        {/* Ut & Rt */}
        <TableBodyContainer>
          <TableBodyTitle>
            <span>R<sub>T</sub> & U<sub>T</sub> berekening</span>
          </TableBodyTitle>
          <div>
            <TableHeader>
              <LeftColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerLayer")}</H2>
              </LeftColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerThickness")}</H2>
                <P {...unitStyles}>{t("units.thicknessUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerLambdaValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerRValue")}</H2>
                <P {...unitStyles}>{t("units.insulationUnit")}</P>
              </RightColumn>
              <RightColumn>
                <H2 {...h2Styles}>{t("thermalCalculation.headerUValue")}</H2>
                <P {...unitStyles}>{t("units.lambdaUnitBottom")}</P>
              </RightColumn>
            </TableHeader>
            <PreliminaryTableBody />
          </div>
        </TableBodyContainer>
        <Separator />
        <TableBodyContainer>
          <TableBodyTitle>
            <span>R<sub>T</sub> & U<sub>T</sub></span>
          </TableBodyTitle>
          <PreliminaryOutcomeTableBody />
        </TableBodyContainer>

        <Separator />

        {/* dU */}
        <TableBodyContainer>
          <TableBodyTitle>
            <span>ΔU correcties</span>
          </TableBodyTitle>
          <CorrectionsTableBody />
        </TableBodyContainer>
        <Separator />
        <TableBodyContainer>
          <TableBodyTitle>
            <span>ΔU</span>
          </TableBodyTitle>
          <CorrectionsOutcomeTableBody />
        </TableBodyContainer>

        <Separator />

        {/* Uc & Rc */}
        <TableBodyContainer>
          <TableBodyTitle>
            <span>R<sub>C</sub> & U<sub>C</sub></span>
          </TableBodyTitle>
          <TotalsOutcomeTableBody />
        </TableBodyContainer>
      </Section>
    </ThermalStateProvider>
  );
};

const TableRowContainer = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableRowLeftColumn = styled(LeftColumn)`
  display: flex;
`;

const TableRowRightColumn = styled(RightColumn)`
  display: flex;
`;

const CustomInput = styled(Input)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  font-weight: 700;
  font-size: ${16 / 16}rem;
  line-height: 16px;
  padding: 10px;
`;

const valueStyle = {
  fontSizePx: 14,
  lineHeightPx: 19,
  color: styles.colors.darkBlue,
  marginTop: '0'
} as TypographyProps;

type TableRowProps = {
  type: string;
  thickness?: number;
  lambdaValue?: number;
  insulationValue?: number;
  UValue?: number;
  editableThickness?: Boolean;
  onEditableThicknessChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  editableThicknessValue?: string;
  editableThicknessValid?: boolean;
  options?: Array<{ label: string; value: string }>;
  onOptionChange?: (event: SyntheticEvent<HTMLSelectElement>) => void;
} & OverviewItemProps;

export const TableRow = ({
  type,
  title,
  text,
  suffix,
  thickness,
  insulationValue,
  UValue,
  lambdaValue,
  editableThickness,
  onEditableThicknessChange,
  editableThicknessValue,
  editableThicknessValid,
  options,
  onOptionChange,
}: TableRowProps) => {
  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <OverviewItem title={title} text={text} suffix={suffix} options={options} optionType={type} onOptionChange={onOptionChange} />
      </TableRowLeftColumn>

      <TableRowRightColumn>
        {editableThickness && (
          <CustomInput
            data-type={type}
            invalid={!editableThicknessValid}
            value={editableThicknessValue ?? thickness}
            onChange={onEditableThicknessChange}
          />
        )}
        {!editableThickness && <P {...valueStyle}>{thickness}</P>}
      </TableRowRightColumn>

      <TableRowRightColumn>
        <P {...valueStyle}>{lambdaValue}</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P {...valueStyle}>{insulationValue}</P>
      </TableRowRightColumn>
      <TableRowRightColumn>
        <P {...valueStyle}>{UValue}</P>
      </TableRowRightColumn>

    </TableRowContainer>
  );
};

export const RSRow = ({ type }: { type: "RSI" | "RSE" }) => {
  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <P
          fontWeight={400}
          fontSizePx={16}
          color={styles.colors.dark2}
          lineHeightPx={20}
        >
          {type}
        </P>
      </TableRowLeftColumn>

      <TableRowRightColumn />
      <TableRowRightColumn />
      <TableRowRightColumn>
        <P {...valueStyle}>
          {type === "RSI"
            ? VENTILATED_WALL_R_VALUE_RSI
            : VENTILATED_WALL_R_VALUE_RSE}
        </P>
      </TableRowRightColumn>
      <TableRowRightColumn />
    </TableRowContainer>
  );
};

const UnitField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const OutcomeRow = ({ type }: { type: "RT" | "UT" | "UD" | "RC" | "UC" }) => {
  const { t } = useTranslation() as any;
  const { performance } = useThermalState();
  const { formatCalc } = useExtendedThermalCalculation()

  let outcomeTitle: any = null
  switch (type) {
    case "RT":
      outcomeTitle = <span>R<sub>T</sub></span>
      break;
    case "UT":
      outcomeTitle = <span>U<sub>T</sub></span>
      break;
    case "UD":
      outcomeTitle = <span>ΔU</span>
      break;
    case "RC":
      outcomeTitle = <span>R<sub>C</sub></span>
      break;
    case "UC":
      outcomeTitle = <span>U<sub>C</sub></span>
      break;
    default:
      return null;
  }

  const renderOutcomeColumns = () => {
    switch (type) {
      case "RT":
        return <>
          <TableRowRightColumn />
          <TableRowRightColumn>
            <UnitField>
              <p dangerouslySetInnerHTML={{ __html: t("units.value").replace("[var]", renderToString(outcomeTitle)) }}></p>
              <Spacer heightPx={16} />
              <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
                {formatCalc(performance.RTValue!)}
              </P>
              <Spacer heightPx={8} />
              <P fontSizePx={12} lineHeightPx={18}>
                {t("units.insulationUnitBottom")}
              </P>
            </UnitField>
          </TableRowRightColumn>
          <TableRowRightColumn />
        </>
      case "UT":
        return <>
          <TableRowRightColumn />
          <TableRowRightColumn />
          <TableRowRightColumn>
            <UnitField>
              <p dangerouslySetInnerHTML={{ __html: t("units.value").replace("[var]", renderToString(outcomeTitle)) }}></p>
              <Spacer heightPx={16} />
              <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
                {formatCalc(performance.UTValue!)}
              </P>
              <Spacer heightPx={8} />
              <P fontSizePx={12} lineHeightPx={18}>
                {t("units.lambdaUnitBottom")}
              </P>
            </UnitField>
          </TableRowRightColumn>
        </>
      case "UD":
        return <>
          <TableRowRightColumn />
          <TableRowRightColumn />
          <TableRowRightColumn>
            <UnitField>
              <p dangerouslySetInnerHTML={{ __html: t("units.value").replace("[var]", renderToString(outcomeTitle)) }}></p>
              <Spacer heightPx={16} />
              <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
                {formatCalc(performance.deltaUValue!)}
              </P>
              <Spacer heightPx={8} />
              <P fontSizePx={12} lineHeightPx={18}>
                {t("units.lambdaUnitBottom")}
              </P>
            </UnitField>
          </TableRowRightColumn>
        </>
      case "RC":
        return <>
          <TableRowRightColumn />
          <TableRowRightColumn>
            <UnitField>
              <p dangerouslySetInnerHTML={{ __html: t("units.value").replace("[var]", renderToString(outcomeTitle)) }}></p>
              <Spacer heightPx={16} />
              <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
                {formatCalc(performance.RCValue!)}
              </P>
              <Spacer heightPx={8} />
              <P fontSizePx={12} lineHeightPx={18}>
                {t("units.insulationUnitBottom")}
              </P>
            </UnitField>
          </TableRowRightColumn>
          <TableRowRightColumn />
        </>
      case "UC":
        return <>
          <TableRowRightColumn />
          <TableRowRightColumn />
          <TableRowRightColumn>
            <UnitField>
              <p dangerouslySetInnerHTML={{ __html: t("units.value").replace("[var]", renderToString(outcomeTitle)) }}></p>
              <Spacer heightPx={16} />
              <P fontWeight={700} fontSizePx={24} lineHeightPx={18}>
                {formatCalc(performance.UCValue!)}
              </P>
              <Spacer heightPx={8} />
              <P fontSizePx={12} lineHeightPx={18}>
                {t("units.lambdaUnitBottom")}
              </P>
            </UnitField>
          </TableRowRightColumn>
        </>
      default:
        return null;
    }
  }

  return (
    <TableRowContainer>
      <TableRowLeftColumn>
        <P
          fontWeight={400}
          fontSizePx={16}
          color={styles.colors.dark2}
          lineHeightPx={20}
        >
          {outcomeTitle}
        </P>
      </TableRowLeftColumn>

      <TableRowRightColumn />
      {renderOutcomeColumns()}
    </TableRowContainer>
  );
};
