"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialSolConfigurationState = void 0;
exports.initialSolConfigurationState = {
    humanReadableName: null,
    solInsulation: {
        type: null,
        material: null,
    },
    solNoiseLevel: {
        type: null,
        material: null,
    },
    solAcousticLayer: {
        type: null,
        material: null,
    },
    solConformity: {
        type: null,
        material: null,
    },
    solBuildingType: {
        type: null,
        material: null,
    },
    files: {
        nl_be: null,
        fr_be: null,
        nl_nl: null,
        fr_fr: null,
        de_de: null,
    },
};
