import {
  NL_TRANSLATIONS,
  NL_BE_TRANSLATIONS,
  FR_BE_TRANSLATIONS,
  NL_NL_TRANSLATIONS,
  FR_FR_TRANSLATIONS,
  DE_DE_TRANSLATIONS,
  Translations,
} from "@iko-design-center/shared"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"

declare module "react-i18next" {
  interface Resources {
    nl: Translations
  }
}

const resources = {
  nl: {
    translation: NL_TRANSLATIONS,
  },
  nl_be: {
    translation: NL_BE_TRANSLATIONS,
  },
  fr_be: {
    translation: FR_BE_TRANSLATIONS,
  },
  nl_nl: {
    translation: NL_NL_TRANSLATIONS,
  },
  fr_fr: {
    translation: FR_FR_TRANSLATIONS,
  },
  de_de: {
    translation: DE_DE_TRANSLATIONS,
  },
}

const languages = ["nl_be", "fr_be", "nl_nl", "fr_fr", "de_de"]
const url = new URL(window.location.href)
const urlLang = url.pathname.split("/")[1]

let lang = "nl_be"
if (languages.some((lang) => urlLang === lang)) {
  lang = urlLang
}
if (url.searchParams.get("lang") && urlLang === "preview") {
  lang = url.searchParams.get("lang") ?? "nl_be"
}

i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
