import React, { SyntheticEvent, useEffect } from "react";
import { CommunicationIcon } from "../common/Icons";
import { StandardModal } from "./StandardModal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useRoutes } from "../../store/routes/hooks";

type CountryAndLanguageProps = {
  handleCountryAndLanguageChanged: () => void;
};

const CountryAndLanguageSelector = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;

  a.button {
    display: block;
    margin: 10px 0;
    padding: 19px 0 19px 60px;
    font-size: 17px;
    color: #000;
    text-decoration: none;
    border-radius: 2px;
    border: 1px solid #fff;

    &:hover {
      border: 1px solid #e7e7e7;
      background-color: #f5f5f5;
    }

    &.country {
      background-image: url("/assets/images/flag-be.svg");
      background-repeat: no-repeat;
      background-position: center left 20px;
      background-size: 30px auto;
    }

    &.nl {
      background-image: url("/assets/images/flag-nl.svg");
    }

    &.fr {
      background-image: url("/assets/images/flag-fr.svg");
    }

    &.de {
      background-image: url("/assets/images/flag-de.svg");
    }
  }

  button {
    display: none;
  }

  @media screen and (pointer: coarse) {
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.65);

    a.button {
      padding: 24px 0 24px 72px;
      background-color: #f5f5f5;
    }
  }
`;

export const CountryAndLanguage = ({
  handleCountryAndLanguageChanged,
}: CountryAndLanguageProps) => {
  const { t } = useTranslation() as any;
  const { Routes } = useRoutes() as any;
  const routePreview = Routes.PREVIEW

  function setCountryAndLanguage(e: SyntheticEvent) {
    e.preventDefault();
    const lang = e.currentTarget.getAttribute("lang") ?? 'nl_be';
    i18n.use(initReactI18next).changeLanguage(lang);
    window.location.href = `/${lang}`
    handleCountryAndLanguageChanged();
  }

  useEffect(() => {
    const languages = ["nl_be", "fr_be", "nl_nl", "fr_fr", "de_de"];
    const url = new URL(window.location.href);
    const urlLang = url.pathname.split("/")[1]

    if (languages.some(lang => urlLang === lang) || url.pathname === routePreview) {
      window.history.replaceState([], document.title, url.href as string);
      handleCountryAndLanguageChanged();
    } else if (urlLang !== undefined && urlLang !== '') {
      window.location.href = "/"
    }
  }, [handleCountryAndLanguageChanged, routePreview]);

  return (
    <CountryAndLanguageSelector>
      <StandardModal
        titleIcon={CommunicationIcon}
        title={t("modal.countryAndLanguage.title")}
        buttonText={"nobutton"}
        classnames={"country-and-language-selector"}
      >
        <a
          href={"/nl_be"}
          lang={"nl_be"}
          onClick={setCountryAndLanguage}
          className={"button country be"}
        >
          {t("modal.countryAndLanguage.beNl")}
        </a>
        <a
          href={"/fr_be"}
          lang={"fr_be"}
          onClick={setCountryAndLanguage}
          className={"button country be"}
        >
          {t("modal.countryAndLanguage.beFr")}
        </a>
        <a
          href={"/nl_nl"}
          lang={"nl_nl"}
          onClick={setCountryAndLanguage}
          className={"button country nl"}
        >
          {t("modal.countryAndLanguage.nlNl")}
        </a>
        <a
          href={"/fr_fr"}
          lang={"fr_fr"}
          onClick={setCountryAndLanguage}
          className={"button country fr"}
        >
          {t("modal.countryAndLanguage.frFr")}
        </a>
        <a
          href={"/de_de"}
          lang={"de_de"}
          onClick={setCountryAndLanguage}
          className={"button country de"}
        >
          {t("modal.countryAndLanguage.deDe")}
        </a>
      </StandardModal>
    </CountryAndLanguageSelector>
  );
};
