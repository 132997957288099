import styled from "styled-components";
import { OutcomeRow, Separator } from "./ExtendedThermalCalculation";

const TableBody = styled.div`
  min-height: 20vh;
`;

export const PreliminaryOutcomeTableBody = () => {
    return (
        <TableBody>
            <OutcomeRow type={"RT"} />
            <Separator margin="0 32px" />
            <OutcomeRow type={"UT"} />
        </TableBody>
    )
}

