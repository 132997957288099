import styled from "styled-components";
import { OutcomeRow, Separator } from "./ExtendedThermalCalculation";

const TableBody = styled.div`
  min-height: 20vh;
`;

export const TotalsOutcomeTableBody = () => {
  return (
    <TableBody>
      <OutcomeRow type={"RC"} />
      <Separator margin="0 32px" />
      <OutcomeRow type={"UC"} />
    </TableBody>
  )
}

