"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadSolDTOSchema = exports.downloadFloorDTOSchema = exports.downloadVentilatedWallDTOSchema = exports.downloadSlopedRoofDTOSchema = exports.downloadCavityWallDTOSchema = exports.downloadFlatRoofDTOSchema = exports.requestAdviceDTOSchema = exports.supportDTOSchema = void 0;
const z = __importStar(require("zod"));
exports.supportDTOSchema = z.object({
    name: z.union([z.string(), z.undefined()]),
    email: z.string(),
    message: z.union([z.string(), z.undefined()]),
    configuration: z.union([z.string(), z.undefined()]),
    country: z.union([z.string(), z.undefined()]),
    language: z.union([z.string(), z.undefined()]),
});
exports.requestAdviceDTOSchema = z.object({
    name: z.string(),
    email: z.string(),
    phone: z.string(),
    postalCode: z.union([z.string(), z.undefined()]),
    company: z.union([z.string(), z.undefined()]),
    message: z.union([z.string(), z.undefined()]),
    configuration: z.union([z.string(), z.undefined()]),
    country: z.union([z.string(), z.undefined()]),
    language: z.union([z.string(), z.undefined()]),
    humanReadableName: z.union([z.string(), z.undefined()]),
});
// FLAT ROOF
exports.downloadFlatRoofDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        topLayerId: z.string(),
        bottomLayerId: z.union([z.string(), z.null()]),
        insulationId: z.string(),
        vaporShieldId: z.string(),
        roofFloorId: z.string(),
    }),
    securingMethods: z.object({
        topLayer: z.string(),
        bottomLayer: z.union([z.string(), z.null()]),
        insulation: z.string(),
        vaporShield: z.string(),
    }),
    specifications: z.object({
        topLayer: z.union([
            z.object({
                nl_be: z.union([z.string(), z.null()]),
                fr_be: z.union([z.string(), z.null()]),
                nl_nl: z.union([z.string(), z.null()]),
                fr_fr: z.union([z.string(), z.null()]),
                de_de: z.union([z.string(), z.null()]),
            }),
            z.null(),
        ]),
        bottomLayer: z.union([
            z.object({
                nl_be: z.union([z.string(), z.null()]),
                fr_be: z.union([z.string(), z.null()]),
                nl_nl: z.union([z.string(), z.null()]),
                fr_fr: z.union([z.string(), z.null()]),
                de_de: z.union([z.string(), z.null()]),
            }),
            z.null(),
        ]),
        insulation: z.union([
            z.object({
                nl_be: z.union([z.string(), z.null()]),
                fr_be: z.union([z.string(), z.null()]),
                nl_nl: z.union([z.string(), z.null()]),
                fr_fr: z.union([z.string(), z.null()]),
                de_de: z.union([z.string(), z.null()]),
            }),
            z.null(),
        ]),
        vaporShield: z.union([
            z.object({
                nl_be: z.union([z.string(), z.null()]),
                fr_be: z.union([z.string(), z.null()]),
                nl_nl: z.union([z.string(), z.null()]),
                fr_fr: z.union([z.string(), z.null()]),
                de_de: z.union([z.string(), z.null()]),
            }),
            z.null(),
        ]),
        finishing: z.union([
            z.object({
                nl_be: z.union([z.string(), z.null()]),
                fr_be: z.union([z.string(), z.null()]),
                nl_nl: z.union([z.string(), z.null()]),
                fr_fr: z.union([z.string(), z.null()]),
                de_de: z.union([z.string(), z.null()]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        topLayer: z.object({
            thickness: z.union([z.number(), z.null()]),
            lambda: z.union([z.number(), z.null()]),
            RValue: z.union([z.number(), z.null()]),
        }),
        bottomLayer: z.object({
            thickness: z.union([z.number(), z.null()]),
            lambda: z.union([z.number(), z.null()]),
            RValue: z.union([z.number(), z.null()]),
        }),
        insulation: z.object({
            thickness: z.union([z.number(), z.null()]),
            lambda: z.union([z.number(), z.null()]),
            RValue: z.union([z.number(), z.null()]),
        }),
        vaporShield: z.object({
            thickness: z.union([z.number(), z.null()]),
            lambda: z.union([z.number(), z.null()]),
            RValue: z.union([z.number(), z.null()]),
        }),
        roofFloor: z.object({
            thickness: z.union([z.number(), z.null()]),
            lambda: z.union([z.number(), z.null()]),
            RValue: z.union([z.number(), z.null()]),
        }),
    }),
    download: z.object({
        technicalFiche: z.object({
            topLayer: z.boolean(),
            bottomLayer: z.boolean(),
            insulation: z.boolean(),
            vaporShield: z.boolean(),
            roofFloor: z.boolean(),
        }),
        DOP: z.object({
            topLayer: z.boolean(),
            bottomLayer: z.boolean(),
            insulation: z.boolean(),
            vaporShield: z.boolean(),
            roofFloor: z.boolean(),
        }),
        roofDetails: z.object({
            roofSurface: z.boolean(),
            roofUpstand: z.boolean(),
            drainagePipe: z.boolean(),
            dome: z.boolean(),
            angleConnection: z.boolean(),
            settlementJoint: z.boolean(),
            verticalWall: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
// CAVITY WALL
exports.downloadCavityWallDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        wallTypeId: z.string(),
        wallInsulationId: z.string(),
        outerCavityLeafId: z.string(),
    }),
    specifications: z.object({
        nl_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        nl_nl: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_fr: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        de_de: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        wallType: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        wallInsulation: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        outerCavityLeaf: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
    }),
    download: z.object({
        technicalFiche: z.object({
            wallType: z.boolean(),
            wallInsulation: z.boolean(),
            outerCavityLeaf: z.boolean(),
        }),
        DOP: z.object({
            wallType: z.boolean(),
            wallInsulation: z.boolean(),
            outerCavityLeaf: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
// SLOPED ROOF
exports.downloadSlopedRoofDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        roofTypeId: z.string(),
        roofInsulationId: z.string(),
    }),
    specifications: z.object({
        nl_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        nl_nl: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_fr: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        de_de: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        roofType: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        roofInsulation: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
    }),
    download: z.object({
        technicalFiche: z.object({
            roofType: z.boolean(),
            roofInsulation: z.boolean(),
        }),
        DOP: z.object({
            roofType: z.boolean(),
            roofInsulation: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
// VENTILATED WALL
exports.downloadVentilatedWallDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        wallTypeId: z.string(),
        wallInsulationId: z.string(),
    }),
    specifications: z.object({
        nl_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        nl_nl: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_fr: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        de_de: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        wallType: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        wallInsulation: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
    }),
    download: z.object({
        technicalFiche: z.object({
            wallType: z.boolean(),
            wallInsulation: z.boolean(),
        }),
        DOP: z.object({
            wallType: z.boolean(),
            wallInsulation: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
// FLOOR
exports.downloadFloorDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        floorInsulationId: z.string(),
        floorScreedId: z.string(),
    }),
    specifications: z.object({
        nl_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        nl_nl: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_fr: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        de_de: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        floorInsulation: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        floorScreed: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
    }),
    download: z.object({
        technicalFiche: z.object({
            floorInsulation: z.boolean(),
            floorScreed: z.boolean(),
        }),
        DOP: z.object({
            floorInsulation: z.boolean(),
            floorScreed: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
// SOL
exports.downloadSolDTOSchema = z.object({
    user: z.object({
        name: z.string(),
        email: z.string(),
        phone: z.string(),
        postalCode: z.string(),
        company: z.string(),
        optin: z.boolean(),
        message: z.union([z.string(), z.undefined()]),
        country: z.union([z.string(), z.undefined()]),
        language: z.union([z.string(), z.undefined()]),
    }),
    screenshots: z.array(z.string()),
    humanReadableName: z.union([z.string(), z.undefined()]),
    overviewScreenshot: z.string(),
    configuration: z.union([z.string(), z.undefined()]),
    materials: z.object({
        solInsulationId: z.string(),
        // solScreedId: z.string(),
    }),
    specifications: z.object({
        nl_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_be: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        nl_nl: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        fr_fr: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
        de_de: z.union([
            z.object({
                url: z.string(),
                type: z.enum(["url", "document"]),
            }),
            z.null(),
        ]),
    }),
    thermalPerformance: z.object({
        UCValue: z.number(),
        RTValue: z.number(),
        solInsulation: z.object({
            thickness: z.number(),
            lambda: z.number(),
            RValue: z.number(),
        }),
        // floorScreed: z.object({
        //   thickness: z.number(),
        //   lambda: z.number(),
        //   RValue: z.number(),
        // }),
    }),
    download: z.object({
        technicalFiche: z.object({
            solInsulation: z.boolean(),
            // solScreed: z.boolean(),
        }),
        DOP: z.object({
            solInsulation: z.boolean(),
            // solScreed: z.boolean(),
        }),
        thermalCalculation: z.boolean(),
        specifications: z.boolean(),
        BIMFile: z.boolean(),
        processingGuideline: z.boolean(),
        screenshots: z.boolean(),
    }),
});
