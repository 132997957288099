import { useTranslation } from "react-i18next"
import { possibleCombinations as possibleCombinationsBE } from "./matrix_be"
import { possibleCombinations as possibleCombinationsNL } from "./matrix_nl"
import { useMemo } from "react"
import {
  BottomLayerTypes,
  InsulationTypes,
  RoofFloorTypes,
  RoofTypeClimateClasses,
  RoofTypeTypes,
  SecuringMethodTypes,
  TopLayerTypes,
  VaporShieldTypes,
  WaterproofingTypes,
} from "@iko-design-center/shared"
import {
  isMobile,
  parseMatrixStringValue,
} from "../../../services/helpers.service"
import { renderer } from "../../viewer/Renderer"
import { Models } from "../../viewer/renderer.api"
import { useFlatRoof } from "../../../store/flat-roof/hooks"
import { useRoutes } from "../../../store/routes/hooks"
import { useHistory } from "react-router"
import { useApplication } from "../../../store/application/hooks"
import { useFlatRoofModels } from "./models.hook"

export function useMatrix() {
  const { i18n } = useTranslation()
  const { configurationState } = useFlatRoof()
  const { currentModel } = useApplication()
  const { isFlatRoofDetailModel } = useFlatRoofModels()
  const { Routes } = useRoutes()
  const history = useHistory()
  const {
    type: stateRoofType,
    climateClass: stateRoofClimateClass,
  } = configurationState.roofType
  const { type: stateRoofFloor } = configurationState.roofFloor
  const { type: stateWaterproofing } = configurationState.waterproofing
  const {
    topLayer: stateTopLayer,
    bottomLayer: stateBottomLayer,
    insulation: stateInsulation,
    vaporShield: stateVaporShield,
    finishing: stateFinishing,
  } = configurationState.securingMethods

  const possibleCombinations = useMemo(() => {
    const i18nInfo = i18n.language.split("_")
    const country = i18nInfo[1]
    switch (country) {
      case "be":
        return possibleCombinationsBE
      case "nl":
        return possibleCombinationsNL

      default:
        return possibleCombinationsBE
    }
  }, [i18n])

  const getRemainingCombinations = (
    roofType: RoofTypeTypes | null = null,
    roofClimateClass: RoofTypeClimateClasses | null = null,
    roofFloor: RoofFloorTypes | null = null,
    roofWaterproofing: WaterproofingTypes | null = null,
    topLayer: any = null,
    bottomLayer: any = null,
    insulation: any = null,
    vaporShield: any = null
  ) => {
    let remainingCombinations = possibleCombinations

    if (roofType) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.roofType === roofType
      )
    }

    if (roofClimateClass) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.climateClass === roofClimateClass
      )
    }

    if (roofFloor) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.roofFloor === roofFloor
      )
    }

    if (roofWaterproofing) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.waterproofing === roofWaterproofing
      )
    }

    if (topLayer) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.topLayer === `${topLayer.type} / ${topLayer.method}`
      )
    }

    if (bottomLayer) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) =>
          c.bottomLayer === `${bottomLayer.type} / ${bottomLayer.method}`
      )
    }

    if (insulation) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) => c.insulation === `${insulation.type} / ${insulation.method}`
      )
    }

    if (vaporShield) {
      remainingCombinations = remainingCombinations.filter(
        (c: any) =>
          c.vaporShield === `${vaporShield.type} / ${vaporShield.method}`
      )
    }

    return remainingCombinations
  }

  const displayDefaultFlatRoofCombination = (options: any = {}) => {
    if (!possibleCombinations) return

    const defaultTypes = {
      roofType: stateRoofType ?? RoofTypeTypes.ENERGY_ROOF,
      roofClimateClass:
        stateRoofClimateClass ?? RoofTypeClimateClasses.CLIMATE_CLASS_3,
      roofFloor: stateRoofFloor ?? RoofFloorTypes.CONCRETE,
    }

    const remainingCombinations = getRemainingCombinations(
      defaultTypes.roofType,
      defaultTypes.roofClimateClass,
      defaultTypes.roofFloor,
      stateWaterproofing,
      stateTopLayer,
      stateBottomLayer,
      stateInsulation,
      stateVaporShield
    )

    if (remainingCombinations.length === 0) {
      history.push(Routes.HOME as any)
      return
    }

    let defaultCombination = remainingCombinations[0]
    if (!stateWaterproofing) {
      defaultCombination =
        remainingCombinations.find((c) => c.default) ?? remainingCombinations[0]
    }

    const roofType = defaultCombination.roofType as RoofTypeTypes
    const roofFloor = defaultCombination.roofFloor as RoofFloorTypes
    const waterproofing = defaultCombination.waterproofing as WaterproofingTypes
    const finishing = defaultCombination.finishing
    const topLayer = parseMatrixStringValue(defaultCombination.topLayer)
    const bottomLayer = parseMatrixStringValue(defaultCombination.bottomLayer)
    const insulation = parseMatrixStringValue(defaultCombination.insulation)
    const vaporShield = parseMatrixStringValue(defaultCombination.vaporShield)

    // console.log(
    //   roofType,
    //   roofFloor,
    //   finishing,
    //   topLayer,
    //   bottomLayer,
    //   insulation,
    //   vaporShield,
    //   waterproofing
    // )

    let model = Models.FLAT_ROOF
    if (roofType === RoofTypeTypes.RETENTION_ROOF) {
      model = Models.FLAT_ROOF_RETENTION
    }
    switch (waterproofing) {
      case WaterproofingTypes.ONE_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
        model = Models.FLAT_ROOF_E_CIRCULAIR
        break

      case WaterproofingTypes.ONE_BITUMINOUS_FLAME_FREE:
        model = Models.FLAT_ROOF_FLAME_FREE
        break

      case WaterproofingTypes.TWO_BITUMINOUS_CIRCULAIR:
      case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR:
      case WaterproofingTypes.TWO_BITUMINOUS_AIR_PURIFYING_CIRCULAIR_FLAME_FREE:
        model = Models.FLAT_ROOF_ATELIA
        break
    }
    // Model from options
    if (options.displayModel && options.displayModel !== Models.DOLLHOUSE) {
      model = options.displayModel
    }

    renderer.displayModel(model).then(() => {
      renderer.hideMarkers()
      if (options.displayMarkers && !isMobile()) {
        setTimeout(() => renderer.displayMarkers(), 0)
      }

      // Set Layers
      renderer.displayRoofType(roofType)
      renderer.displayRoofFloor(roofFloor)
      renderer.displayTopLayerMaterial(topLayer?.type)
      renderer.displaySecuringMethod("TOPLAAG-BEVESTIGING", topLayer?.method)
      renderer.displayBottomLayerMaterial(bottomLayer?.type)
      renderer.displaySecuringMethod(
        "ONDERLAAG-BEVESTIGING",
        bottomLayer?.method
      )
      renderer.displayInsulationMaterial(insulation?.type)
      renderer.displaySecuringMethod("ISOLATIE-BEVESTIGING", insulation?.method)
      renderer.displayVaporShieldMaterial(vaporShield?.type)
      renderer.displaySecuringMethod(
        "DAMPSCHERM-BEVESTIGING",
        vaporShield?.method
      )

      // Toggle Layers
      setTimeout(() => {
        // Show
        renderer.toggleLayer("TOPLAAG", true)
        renderer.toggleLayer("TOPLAAG-BEVESTIGING", true)
        renderer.toggleLayer("ONDERLAAG", true)
        renderer.toggleLayer("ONDERLAAG-BEVESTIGING", true)
        renderer.toggleLayer("ISOLATIE", true)
        renderer.toggleLayer("ISOLATIE-BEVESTIGING", true)
        renderer.toggleLayer("DAMPSCHERM", true)
        renderer.toggleLayer("DAMPSCHERM-BEVESTIGING", true)

        // Hide
        // if (!topLayer) {
        //   renderer.toggleLayer("TOPLAAG", false)
        //   renderer.toggleLayer("TOPLAAG-BEVESTIGING", false)
        // }

        if (!bottomLayer) {
          renderer.toggleLayer("ONDERLAAG", false)
          renderer.toggleLayer("ONDERLAAG-BEVESTIGING", false)
        }

        // if (!insulation) {
        //   renderer.toggleLayer("ISOLATIE", false)
        //   renderer.toggleLayer("ISOLATIE-BEVESTIGING", false)
        // }

        // if (!vaporShield) {
        //   renderer.toggleLayer("DAMPSCHERM", false)
        //   renderer.toggleLayer("DAMPSCHERM-BEVESTIGING", false)
        // }

        if (roofType === RoofTypeTypes.STANDARD_ROOF && !finishing) {
          renderer.toggleLayers("DAKBEDEKKING", false)
        }

        if (finishing) {
          // Show ballast finishing on top
          renderer.displayRoofType(RoofTypeTypes.LIVING_ROOF)

          // Add KIMFIXATIE for the ONDERLAAG layer
          if (bottomLayer && bottomLayer.method === SecuringMethodTypes.LOOSE) {
            renderer.displaySecuringMethod(
              "ONDERLAAG-BEVESTIGING",
              SecuringMethodTypes.MECHANICAL_BALLAST
            )
          }
        }
      }, 0)
    })
  }

  const appendFlatRoofStateToUrl = () => {
    const urlWithoutParams = window.location.href.split("?")[0]
    const url = new URL(urlWithoutParams ?? window.location.href)

    // Roof type
    const roofTypeEnum = getEnumKey(stateRoofType, RoofTypeTypes)
    if (roofTypeEnum) url.searchParams.append("roof-type", roofTypeEnum)

    // Climate class
    const climateClassEnum = getEnumKey(
      stateRoofClimateClass,
      RoofTypeClimateClasses
    )
    if (climateClassEnum)
      url.searchParams.append("climate-class", climateClassEnum)

    // Roof floor
    const roofFloorEnum = getEnumKey(stateRoofFloor, RoofFloorTypes)
    if (roofFloorEnum) url.searchParams.append("roof-floor", roofFloorEnum)

    // Waterproofing
    const waterproofingEnum = getEnumKey(stateWaterproofing, WaterproofingTypes)
    if (waterproofingEnum)
      url.searchParams.append("waterproofing", waterproofingEnum)

    // Top layer
    const topLayerEnum = getEnumKey(stateTopLayer?.type, TopLayerTypes)
    const topLayerSecuringMethodEnum = getEnumKey(
      stateTopLayer?.method,
      SecuringMethodTypes
    )
    if (topLayerEnum && topLayerSecuringMethodEnum)
      url.searchParams.append(
        "toplayer",
        `${topLayerEnum} / ${topLayerSecuringMethodEnum}`
      )

    // Bottom layer
    const bottomLayerEnum = getEnumKey(stateBottomLayer?.type, BottomLayerTypes)
    const bottomLayerSecuringMethodEnum = getEnumKey(
      stateBottomLayer?.method,
      SecuringMethodTypes
    )
    if (bottomLayerEnum && bottomLayerSecuringMethodEnum)
      url.searchParams.append(
        "bottomlayer",
        `${bottomLayerEnum} / ${bottomLayerSecuringMethodEnum}`
      )

    // Insulation
    const insulationEnum = getEnumKey(stateInsulation?.type, InsulationTypes)
    const insulationSecuringMethodEnum = getEnumKey(
      stateInsulation?.method,
      SecuringMethodTypes
    )
    if (insulationEnum && insulationSecuringMethodEnum)
      url.searchParams.append(
        "insulation",
        `${insulationEnum} / ${insulationSecuringMethodEnum}`
      )

    // Vapor Shield
    const vaporShieldEnum = getEnumKey(stateVaporShield?.type, VaporShieldTypes)
    const vaporShieldSecuringMethodEnum = getEnumKey(
      stateVaporShield?.method,
      SecuringMethodTypes
    )
    if (vaporShieldEnum && vaporShieldSecuringMethodEnum)
      url.searchParams.append(
        "vaporshield",
        `${vaporShieldEnum} / ${vaporShieldSecuringMethodEnum}`
      )

    // Finishing
    if (stateFinishing) url.searchParams.append("finishing", stateFinishing)

    // Detail model
    if (currentModel && isFlatRoofDetailModel(currentModel)) {
      url.searchParams.append("detail", currentModel)
    }

    window.history.replaceState([], document.title, url.href as string)
  }

  const getEnumKey = (enumValue: string | null, EnumTypes: any) => {
    if (!enumValue) return null
    return Object.keys(EnumTypes)[Object.values(EnumTypes).indexOf(enumValue)]
  }

  return {
    possibleCombinations,
    appendFlatRoofStateToUrl,
    getRemainingCombinations,
    displayDefaultFlatRoofCombination,
  }
}
