"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialSlopedRoofConfigurationState = void 0;
exports.initialSlopedRoofConfigurationState = {
    humanReadableName: null,
    roofType: {
        type: null,
        material: null,
    },
    roofInsulation: {
        type: null,
        material: null,
    },
    files: {
        nl_be: null,
        fr_be: null,
        nl_nl: null,
        fr_fr: null,
        de_de: null,
    },
};
