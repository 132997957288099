import {
  VentilatedWallWallTypeTypes,
  VentilatedWallFacadeCladdingTypes,
  VentilatedWallInsulationTypes,
} from "@iko-design-center/shared"
import {
  ExtendedVentilatedWallWallTypeTypes,
  VentilatedWallVaporShieldTypes,
  VentilationTypes,
} from "@iko-design-center/shared/lib/constants/constants"

export function getWallTypeTranslations(
  t: any,
  type: VentilatedWallWallTypeTypes | ExtendedVentilatedWallWallTypeTypes
) {
  switch (type) {
    case VentilatedWallWallTypeTypes.CONCRETE_WALL:
      return t("ventilatedWall.wallType.concrete")
    case VentilatedWallWallTypeTypes.LIME_SAND_WALL:
      return t("ventilatedWall.wallType.limeSand")
    case VentilatedWallWallTypeTypes.HOLLOW_STONE_WALL:
      return t("ventilatedWall.wallType.hollowStone")
    case VentilatedWallWallTypeTypes.CROSSWOOD_WALL:
      return t("ventilatedWall.wallType.crosswood")

    case ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1100_GLUED")
    case ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1100_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1100_BRICKED")
    case ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_GLUED:
      return t("material.EXT_HOLLOW_STONE_WALL_1400_GLUED")
    case ExtendedVentilatedWallWallTypeTypes.EXT_HOLLOW_STONE_WALL_1400_BRICKED:
      return t("material.EXT_HOLLOW_STONE_WALL_1400_BRICKED")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_2400:
      return t("material.EXT_CONCRETE_WALL_2400")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_400:
      return t("material.EXT_CONCRETE_WALL_400")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_500:
      return t("material.EXT_CONCRETE_WALL_500")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_600:
      return t("material.EXT_CONCRETE_WALL_600")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_700:
      return t("material.EXT_CONCRETE_WALL_700")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CONCRETE_WALL_800:
      return t("material.EXT_CONCRETE_WALL_800")
    case ExtendedVentilatedWallWallTypeTypes.EXT_LIME_SAND_WALL_1850:
      return t("material.EXT_LIME_SAND_WALL_1850")
    case ExtendedVentilatedWallWallTypeTypes.EXT_CROSSWOOD_WALL:
      return t("material.EXT_CROSSWOOD_WALL")
    default:
      return "Not available"
  }
}

export function getFacadeCladdingTranslations(
  t: any,
  type: VentilatedWallFacadeCladdingTypes
) {
  switch (type) {
    case VentilatedWallFacadeCladdingTypes.ETERNIT_CEDRAL:
      return t("ventilatedWall.facadeCladding.cedral")
    case VentilatedWallFacadeCladdingTypes.ETERNIT_EQUITONE:
      return t("ventilatedWall.facadeCladding.equitone")
    case VentilatedWallFacadeCladdingTypes.TRESPA_CLADDING:
      return t("ventilatedWall.facadeCladding.trespa")
    case VentilatedWallFacadeCladdingTypes.ROCKPANEL_CLADDING:
      return t("ventilatedWall.facadeCladding.rockpanel")
    case VentilatedWallFacadeCladdingTypes.SWISS_PEARL_CLADDING:
      return t("ventilatedWall.facadeCladding.swissPearl")
    case VentilatedWallFacadeCladdingTypes.FIBERPLAST_CLADDING:
      return t("ventilatedWall.facadeCladding.fiberplast")
    case VentilatedWallFacadeCladdingTypes.KERALIT_CLADDING:
      return t("ventilatedWall.facadeCladding.keralit")
    default:
      return "Not available"
  }
}

export function getWallInsulationTranslations(
  t: any,
  type: VentilatedWallInsulationTypes
) {
  switch (type) {
    case VentilatedWallInsulationTypes.ENERTHERM_ALU_PURE:
      return t("material.ENERTHERM_ALU_PURE")
    default:
      return "Not available"
  }
}

export function getWallCavityTranslations(t: any, type: VentilationTypes) {
  switch (type) {
    case VentilationTypes.NO_VENTILATION:
      return t("material.NO_VENTILATION")
    case VentilationTypes.WEAK_VENTILATION:
      return t("material.WEAK_VENTILATION")
    case VentilationTypes.STRONG_VENTILATION:
      return t("material.STRONG_VENTILATION")
    default:
      return "Not available"
  }
}

export function getWallVaporShieldTranslations(
  t: any,
  type: VentilatedWallVaporShieldTypes
) {
  switch (type) {
    case VentilatedWallVaporShieldTypes.WATERPROOF_VAPOR_SHIELD:
      return t("material.WATERPROOF_VAPOR_SHIELD")
    case VentilatedWallVaporShieldTypes.NO_WATERPROOF_VAPOR_SHIELD:
      return t("material.NO_WATERPROOF_VAPOR_SHIELD")
    case VentilatedWallVaporShieldTypes.CEMENT_WINDBREAKER:
      return t("material.CEMENT_WINDBREAKER")
    default:
      return "Not available"
  }
}
