import styled from "styled-components";
import { OutcomeRow } from "./ExtendedThermalCalculation";

const TableBody = styled.div`
`;

export const CorrectionsOutcomeTableBody = () => {
    return (
        <TableBody>
            <OutcomeRow type={"UD"} />
        </TableBody>
    )
}

